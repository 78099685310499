import axios from 'axios';

// Create an Axios instance
const instance = axios.create({
    timeout: 5000, // Optional timeout
});

// Add a request interceptor
instance.interceptors.request.use(
    (config) => {
        const currency = localStorage.getItem('currency');

        if (currency) {
            // For GET requests, add the currency as a query parameter
            if (config.method === 'get') {
                config.params = {
                    ...config.params,
                    currency,
                };
            } else {
                // For other methods (POST, PUT, etc.), add the currency to the request body
                if (config.data && typeof config.data === 'object') {
                    config.data.currency = currency;
                } else {
                    config.data = { currency };
                }
            }
        }

        return config;
    },
    (error) => {
        // Handle the error
        return Promise.reject(error);
    }
);

export default instance;
