<template>
  <div class="menu">
    <ul class="nav">
      <template v-for="item in $parent.nav">
        <li class="nav__item" :key="item.title">
          <router-link class="title small" :to="item.path" v-if="item.path != 'social'">
            {{ item.title }}
          </router-link>
          <div class="title small" v-if="item.path === 'social'" @click="$parent.scrollToSocial">
            {{ item.title }}
          </div>
        </li>
      </template>
      <li v-if="currencyCodes && currencyCodes.length > 1" class="nav__item">
        <Select2 :model-value="$parent.currency" @select="changeCurrency($event.text)"
                 @change="changeCurrency($event)" :settings="{minimumResultsForSearch: -1}" :options="currencyCodes"/>
      </li>
    </ul>
  </div>
</template>

<script>
import Select2 from "vue3-select2-component";


export default {
  name: 'Menu',
  components: {
    Select2
  },
  props: {
    currencyCodes: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {}
  },
  methods: {
    changeCurrency(val) {
      this.$emit('update-currency', val);
      localStorage.setItem('currency', val);
    }
  },
}
</script>
